import React from 'react';
import dynamic from 'next/dynamic';

// components
import { MainNav } from 'src/components/navigation';
import { MainMenu } from 'src/sections/Navigation';
import { ConsentManager } from 'src/containers/ConsentManager';
import Footer from 'src/components/footer';
import { UnidentifiedTraffic } from 'src/components/Feedback';
import { MetaTags } from 'src/components/MetaTags';
import { Section } from 'src/components-v2/Layout';
import { ContentfulToolbar } from 'src/components/ContentfulToolbar';
import { MainNavigationBar } from 'src/components-v2/MainNavigationBar';
import { CmsThemeContextProvider } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';

// helpers
import { AnalyticsProvider, LocaleContext } from 'src/contexts';
import { CmsThemes, Locales } from 'src/lib/constants';

// scripts
import initAnalytics from 'public/assets/js/analytics';
import 'public/assets/js/analytics/utilities';

const ForeignContentNotification = dynamic(
  () => {
    return import(
      'src/components/Feedback/ForeignContentNotification/ForeignContentNotification'
    );
  },
  {
    ssr: false,
  },
);

// For both, new static and new CMS pages (using in Page template)
const MainTemplate = ({
  children,
  locale = '',
  hasForeignContentNotification = true,
  hasFooter = true,
  hasNavigation = true,
  cmsTheme = 'web 2.0',
  sx = {},
  ...props
}) => {
  React.useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <CmsThemeContextProvider cmsTheme={cmsTheme}>
      <LocaleContext.Provider value={locale || Locales.EN_US}>
        <AnalyticsProvider>
          {/* @ts-ignore */}
          <MetaTags {...props} />

          {hasNavigation &&
            (cmsTheme === CmsThemes.WEB3 ? (
              <MainNavigationBar />
            ) : locale === Locales.EN_GB || locale === Locales.EN_EU ? (
              <>
                <MainNav {...props} />

                {hasForeignContentNotification && (
                  <ForeignContentNotification />
                )}
              </>
            ) : (
              <MainMenu />
            ))}

          <Section
            component='main'
            id='main-content'
            sx={{
              bgcolor: 'white',
              ...sx,
            }}
          >
            {children}
          </Section>
          {/* @ts-ignore */}
          {hasFooter && <Footer {...props} />}
          <ConsentManager />
          <UnidentifiedTraffic />
          <ContentfulToolbar />
        </AnalyticsProvider>
      </LocaleContext.Provider>
    </CmsThemeContextProvider>
  );
};

export default MainTemplate;
